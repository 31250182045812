import { SET_PRO_ACCOUNT_DATA, SET_ONBOARDING_COMPLETED } from './actions';

const initialState = {
    monthly_credits: 0,
    remaining_credits: 0,
    plan: '',
    country: '',
    onboarding_completed: true
  };
  
  const proAccountReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PRO_ACCOUNT_DATA:
        return {
          ...state,
          ...action.payload,
        };
      case SET_ONBOARDING_COMPLETED:
        return {
          ...state,
          ...action.payload
        };
      default:
        return state;
    }
  };
  
  export default proAccountReducer;