import { 
    ADD_COMPETITOR,
    UPDATE_ALL_COMPETITORS,
    TOGGLE_SELECT_COMPETITOR,
    TOGGLE_INCLUDE,
    ADD_COMPETITORS_ANALYSIS_RESULTS,
    UPDATE_COMPETITORS_HISTORY,
    DESELECT_ALL_COMPETITORS,
    SET_COMPETITORS_ANALYSIS_RESULT_STATUS,
    RESET_ALL_STATE
 } from './actions';




const initialState = {
    
    results: {},
    competitorsAnalysisResults: [],
    competitorsAnalysisResultsStatus: null,
    competitorsHistory: []

};

const competitorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case RESET_ALL_STATE:
            return initialState;
        case ADD_COMPETITOR: {
            const newCompetitor = { ...action.payload, selected: action.payload.type === 'service' };
            // return {
            //     ...state,
            //     results: [...state.results, newCompetitor]
            // };
            return {
                ...state,
                results: {
                    ...state.results,
                    final_answer: {
                        ...state.results.final_answer,
                        result: {
                            ...state.results.final_answer.result,
                            results: [...state.results.final_answer.result.results, newCompetitor],
                        },
                    },
                },
            };
        }

        case UPDATE_ALL_COMPETITORS: {

            return {
                ...state,
                results: action.payload
            };
        }

        case SET_COMPETITORS_ANALYSIS_RESULT_STATUS: {
            return {
                ...state,
                competitorsAnalysisResultsStatus: action.payload

            }
        }

        case TOGGLE_SELECT_COMPETITOR: {
            const toggledResults = state.results.final_answer.result.results.map((competitor) => {
                if (competitor.name === action.payload.name) {
                    return { ...competitor, selected: !competitor.selected };
                }
                return competitor;
            });
            return {
                ...state,
                results: toggledResults
            };
        }

        case TOGGLE_INCLUDE: {
            const updatedResults = state.results.final_answer.result.results.map((result, index) => {
                // if (index === 0) {
                //     // Keep the first item unchanged
                //     return result;
                // }
                
                // Apply the toggle logic to the inner array at index 1
                if (result.service === action.payload.service) {
                    console.log(index)
                    return { ...result, include: !result.include };
                }
                else {
                    return result
                }
                
            });

            return {
                ...state,
                results: {
                    ...state.results,
                    final_answer: {
                        ...state.results.final_answer,
                        result: {
                            ...state.results.final_answer.result,
                            results: updatedResults,
                        },
                    },
                },
            };
        }
        
        case ADD_COMPETITORS_ANALYSIS_RESULTS: {
            return {
                ...state,
                competitorsAnalysisResults: action.payload, // Overwrite with the new data
            };
        }

        case UPDATE_COMPETITORS_HISTORY: {
            return {
                ...state,
                competitorsHistory: action.payload
            }
        }

        case DESELECT_ALL_COMPETITORS: {
            const deselectedResults = state.results.final_answer.result.results.map((competitor) => ({
                ...competitor,
                include: false
            }));
 
            return {
                ...state,
                results: {
                    ...state.results,
                    final_answer: {
                        ...state.results.final_answer,
                        result: {
                            ...state.results.final_answer.result,
                            results: deselectedResults,
                        },
                    },
                },
            };
        }

        default:
            return state;
    }
};

export default competitorsReducer;

