import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import { PersonOutlined, AutorenewOutlined, ExitToAppOutlined } from '@mui/icons-material';

const RetentionAnalytics = ({ data }) => {
  if (!data) return null;

  const retentionMetrics = [
    { label: 'Same Day', key: 'same_day', icon: PersonOutlined, color: 'primary.main' },
    { label: '7 Days', key: '7_days', icon: AutorenewOutlined, color: 'success.main' },
    { label: '30 Days', key: '30_days', icon: AutorenewOutlined, color: 'info.main' },
    { label: '90 Days', key: '90_days', icon: AutorenewOutlined, color: 'warning.main' },
    { label: '180 Days', key: '180_days', icon: AutorenewOutlined, color: 'secondary.main' },
    { label: '365 Days', key: '365_days', icon: ExitToAppOutlined, color: 'error.main' },
  ];

  return (
    <Grid container spacing={3}>
      {/* Retention Cards */}
      {retentionMetrics.map((metric) => {
        const Icon = metric.icon;
        return (
          <Grid item xs={12} md={4} key={metric.key}>
            <Card>
              <CardContent>
                <Typography variant="h6" color="textSecondary">
                  {metric.label} Retention
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                  <Icon sx={{ fontSize: 40, color: metric.color, mr: 2 }} />
                  <Typography variant="h3">
                    {data.overall_retention[metric.key].percentage}%
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ ml: 2 }}>
                    ({data.overall_retention[metric.key].count} users)
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        );
      })}

      {/* Cohort Table */}
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Subscribed</TableCell>
                <TableCell>Start value</TableCell>
                {retentionMetrics.map((period) => (
                  <TableCell key={period.key} align="right">{period.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(data.cohort_analysis).map(([month, cohort]) => (
                <TableRow key={month}>
                  <TableCell>{month}</TableCell>
                  <TableCell>{cohort.total_users}</TableCell>
                  {retentionMetrics.map((period) => (
                    <TableCell 
                      key={period.key} 
                      align="right"
                      sx={{
                        backgroundColor: `rgba(92, 107, 192, ${cohort.retention[period.key].percentage / 100})`
                      }}
                    >
                      {cohort.retention[period.key].percentage}%
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default RetentionAnalytics; 