import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import config from 'config';

const AdminGuard = ({ children }) => {
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const auth = getAuth();
  const apiUrl = config.apiUrl;

  useEffect(() => {
    const verifyAdmin = async () => {
      const user = auth.currentUser;
      
      if (!user) {
        navigate('/login', { replace: true });
        return;
      }

      try {
        const token = await user.getIdToken();
        const response = await axios.get(`${apiUrl}/analytics/check-staff-status/`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        });

        if (!response.data.is_staff) {
          navigate('/', { replace: true });
        } else {
          setIsVerified(true);
        }
      } catch (error) {
        console.error('Staff verification failed:', error);
        navigate('/', { replace: true });
      }
    };

    verifyAdmin();
  }, [navigate, apiUrl]);

  return isVerified ? children : null;
};

AdminGuard.propTypes = {
  children: PropTypes.node
};

export default AdminGuard; 