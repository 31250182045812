import { SET_UTM_PARAMS } from './actions';

const storedUtmParams = JSON.parse(localStorage.getItem('utmParams')) || {};

const initialState = {
  utm_source: storedUtmParams.utm_source || '',
  utm_medium: storedUtmParams.utm_medium || '',
  utm_campaign: storedUtmParams.utm_campaign || '',
  referrer: storedUtmParams.referrer || '',
  ref: storedUtmParams.ref || ''
};

const utmParamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UTM_PARAMS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default utmParamsReducer;