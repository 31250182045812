// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import chat from './chat';
import loadingReducer from './loading-reducer';
import comparisonReducer from './comparison';
import analysisReducer from './analyzer';
import addToComparisonReducer from './comparison-list';
import addErrorMessage from './error-reducer'
import comparisonParamsReducer from './comparison-params'
import allComparisonParamsReducer from './all-comparison-params'
import analysisUrlReducer from './analysisUrlReducer';
import historyReducer from './history-reducer';
import currentTaskReducer from './task-id';
import swotReducer from './swot-result';
import competitorsReducer from './competitors-reducer';
import currentURLReducer from './current-url'
import proAccountReducer from './pro-plan-status';
import extendedAnalysisReducer from './extended-analysis-reducer';
import publicDataReducer from './public-directory';
import publicDataListReducer from './public-directory-list';
import reviewsReducer from './reviews';
import modalReducer from './modal';
import utmParamsReducer from './utmParamsReducer';
import interviewReducer from './interview-reducer';
import bannerReducer from './banner';
import wtfAnalysisReducer from './wtf-analysis';

const appReducer = combineReducers({
  menu,
  snackbar,
  chat,
  loadingReducer,
  comparisonReducer,
  analysisReducer,
  addToComparisonReducer,
  addErrorMessage,
  comparisonParamsReducer,
  allComparisonParamsReducer,
  analysisUrlReducer,
  historyReducer,
  currentTaskReducer,
  swotReducer,
  competitorsReducer,
  currentURLReducer,
  proAccountReducer,
  extendedAnalysisReducer,
  publicDataReducer,
  publicDataListReducer,
  reviewsReducer,
  modalReducer,
  utmParamsReducer,
  interviewReducer,
  bannerReducer,
  wtfAnalysis: wtfAnalysisReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_ALL_STATE') {
    state = undefined;
    return appReducer(undefined, { type: '@@INIT' });
  }
  return appReducer(state, action);
};

export default rootReducer;
