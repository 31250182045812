// action - account reducer
export const LOGIN = '@auth/LOGIN';
export const LOGOUT = '@auth/LOGOUT';
export const REGISTER = '@auth/REGISTER';
export const ADD_USER_MESSAGE = 'ADD_USER_MESSAGE';
export const SET_ANSWER_DATA = 'SET_ANSWER_DATA';
export const SET_LOADING = 'SET_LOADING';
export const ADD_COMPARISON_ANSWER = 'ADD_COMPARISON_ANSWER';
export const ADD_ANALYSIS_ANSWER = 'ADD_ANALYSIS_ANSWER';
export const ADD_TO_COMPARISON = 'ADD_TO_COMPARISON';
export const ADD_ERROR_MESSAGE = 'ADD_ERROR_MESSAGE';
export const REMOVE_ERROR_MESSAGE = 'REMOVE_ERROR_MESSAGE';
export const REMOVE_FROM_COMPARISON = 'REMOVE_FROM_COMPARISON';
export const ADD_PARAMETER = 'ADD_PARAMETER';
export const UPDATE_PARAMETER = 'UPDATE_PARAMETER';
export const DELETE_PARAMETER = 'DELETE_PARAMETER';
export const RESTORE_DEFAULT_PARAMETERS = 'RESTORE_DEFAULT_PARAMETERS'
export const DESELECT_ALL_PARAMETERS = 'DESELECT_ALL_PARAMETERS'
export const ADD_PARAMETER_TO_ALL = 'ADD_PARAMETER_TO_ALL';
export const DELETE_PARAMETER_FROM_ALL = 'DELETE_PARAMETER_FROM_ALL';
export const SET_ANALYSIS_URL = 'SET_ANALYSIS_URL';
export const SET_HISTORY_DRAWER_STATE = 'SET_HISTORY_DRAWER_STATE';
export const SET_HISTORY_DATA = 'SET_HISTORY_DATA';
export const SET_HISTORY_RESULT = 'SET_HISTORY_RESULT';
export const SET_CURRENT_TASK_ID = 'SET_CURRENT_TASK_ID'
export const SET_SWOT_RESULT = 'SET_SWOT_RESULT'
export const SET_LOADING_SWOT = 'SET_LOADING_SWOT'
export const ADD_COMPETITOR = 'ADD_COMPETITOR'
export const UPDATE_ALL_COMPETITORS = 'UPDATE_ALL_COMPETITORS'
export const TOGGLE_SELECT_COMPETITOR = 'TOGGLE_SELECT_COMPETITOR'
export const SET_CURRENT_URL = 'SET_CURRENT_URL'
export const TOGGLE_INCLUDE = 'TOGGLE_INCLUDE';
export const SET_LOADING_COMPETITORS = 'SET_LOADING_COMPETITORS';
export const ADD_COMPETITORS_ANALYSIS_RESULTS = 'ADD_COMPETITORS_ANALYSIS_RESULTS';
export const UPDATE_COMPETITORS_HISTORY = 'UPDATE_COMPETITORS_HISTORY';
export const DESELECT_ALL_COMPETITORS = 'DESELECT_ALL_COMPETITORS';
export const SET_LOADING_COMPETITORS_RESULTS = 'SET_LOADING_COMPETITORS_RESULTS';
export const SET_COMPETITORS_ANALYSIS_RESULT_STATUS = 'SET_COMPETITORS_ANALYSIS_RESULT_LENGTH';
export const SET_PRO_ACCOUNT_DATA = 'SET_PRO_ACCOUNT_DATA';
export const SET_EXTENDED_ANALYSIS_RESULTS = 'SET_EXTENDED_ANALYSIS_RESULTS';
export const SET_PUBLIC_DATA = 'SET_PUBLIC_DATA';
export const SET_PUBLIC_LIST_VALUES = 'SET_PUBLIC_LIST_VALUES';
export const SET_REVIEWS_ANALYSIS_RESULTS = 'SET_REVIEWS_ANALYSIS_RESULTS';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_UTM_PARAMS = 'SET_UTM_PARAMS';
export const UPDATE_ANALYSIS_TASK_ID = 'UPDATE_ANALYSIS_TASK_ID';
export const SET_INTERVIEW_SERVICE_URL = 'SET_INTERVIEW_SERVICE_URL';
export const SET_URL_FETCH_STATUS = 'SET_URL_FETCH_STATUS';
export const SET_SEGMENTS = 'SET_SEGMENTS';
export const SET_SELECTED_SEGMENT = 'SET_SELECTED_SEGMENT';
export const SET_QUESTIONNAIRE = 'SET_QUESTIONNAIRE';
export const ADD_ANSWER = 'ADD_ANSWER';
export const UPDATE_ANSWER = 'UPDATE_ANSWER';
export const RESET_INTERVIEW = 'RESET_INTERVIEW';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SET_INTERVIEW_TYPE = 'SET_INTERVIEW_TYPE';
export const SET_INTERVIEW_GOAL = 'SET_INTERVIEW_GOAL';
export const SET_INTERVIEW_TASK_ID = 'SET_INTERVIEW_TASK_ID';
export const EDIT_QUESTION = 'EDIT_QUESTION';
export const ADD_NEW_QUESTION = 'ADD_NEW_QUESTION';
export const DELETE_SECTION = 'DELETE_SECTION';
export const DIVE_DEEPER = 'DIVE_DEEPER';
export const SET_SECTION_LOADING = 'SET_SECTION_LOADING';
export const UPDATE_INTERVIEWS_HISTORY = 'UPDATE_INTERVIEWS_HISTORY';
export const SET_INTERVIEWS_HISTORY_RESULT = 'SET_INTERVIEWS_HISTORY_RESULT';
export const SET_INTERVIEWS_PAGINATION = 'SET_INTERVIEWS_PAGINATION';
export const SET_SELECTED_INTERVIEW = 'SET_SELECTED_INTERVIEW';
export const SET_INTERVIEW_RUNNING = 'SET_INTERVIEW_RUNNING';
export const SET_INTERVIEW_THEMES = 'SET_INTERVIEW_THEMES';
export const SET_INTERVIEW_INSIGHTS = 'SET_INTERVIEW_INSIGHTS';
export const SET_AFFINITY_MAPPINGS = 'SET_AFFINITY_MAPPINGS';

// Task Status Action Types
export const SET_TASK_STATUS = 'SET_TASK_STATUS';
export const SET_TASK_PROGRESS = 'SET_TASK_PROGRESS';
export const SET_TASK_ERROR = 'SET_TASK_ERROR';
export const SET_SEGMENTATION_TASK_ID = 'SET_SEGMENTATION_TASK_ID';
export const SET_QUESTIONNAIRE_TASK_ID = 'SET_QUESTIONNAIRE_TASK_ID';
export const SET_QUESTIONNAIRE_STATUS = 'SET_QUESTIONNAIRE_STATUS';
export const SET_QUESTIONNAIRE_PROGRESS = 'SET_QUESTIONNAIRE_PROGRESS';
export const SET_QUESTIONNAIRE_ERROR = 'SET_QUESTIONNAIRE_ERROR';

// Add these with the other action exports at the top
export const SET_INTERVIEW_STATUS = 'SET_INTERVIEW_STATUS';
export const SET_ANALYSIS_STATUS = 'SET_ANALYSIS_STATUS';
// Add these with the other action exports at the top
export const SET_WTF_ANALYSIS_RESULTS = 'SET_WTF_ANALYSIS_RESULTS';
export const SET_WTF_ANALYSIS_ERROR = 'SET_WTF_ANALYSIS_ERROR';
export const SET_WTF_ANALYSIS_STATUS = 'SET_WTF_ANALYSIS_STATUS';

// Add with other action constants
export const SET_LOADER_STATUS = 'SET_LOADER_STATUS';

// Add these with your other action exports
export const CLOSE_BANNER = 'CLOSE_BANNER';
export const RESET_BANNER = 'RESET_BANNER';

// Add this with other action types at the top
export const SET_ONBOARDING_COMPLETED = 'SET_ONBOARDING_COMPLETED';

// Add this with other action constants at the top
export const RESET_ALL_STATE = 'RESET_ALL_STATE';

// action creators
export const openModal = (modalData) => ({
  type: OPEN_MODAL,
  payload: modalData,
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const setReviewsURL = (data) => ({
  type: SET_REVIEWS_ANALYSIS_RESULTS,
  payload: data,
});

export const setReviewsAnalysisResults = (data) => ({
  type: SET_REVIEWS_ANALYSIS_RESULTS,
  payload: data,
});



export const setPublicListValues = (currentPageData, totalPages, currentPage) => ({
  type: SET_PUBLIC_LIST_VALUES,
  payload: {
    currentPageData,
    totalPages,
    currentPage,
  },
});

export const setPublicData = (data) => ({
  type: SET_PUBLIC_DATA,
  payload: data,
});

export const setExtendedAnalysisResults = (data) => ({
  type: SET_EXTENDED_ANALYSIS_RESULTS,
  payload: data,
});

export const setProAccountData = (data) => ({
  type: SET_PRO_ACCOUNT_DATA,
  payload: data,
});

export const setCompetitorsAnalysisResultStatus = (CompetitorsAnalysisResultStatus) => ({
  type: SET_COMPETITORS_ANALYSIS_RESULT_STATUS,
  payload: CompetitorsAnalysisResultStatus,
});



export const setLoadingCompetitorsResults = (loadingCompetitorsResults) => ({
  type: SET_LOADING_COMPETITORS_RESULTS,
  payload: loadingCompetitorsResults,
});

export const deselectAllCompetitors = () => ({
  type: DESELECT_ALL_COMPETITORS
});

export const updateCompetitorsHistory = (data) => ({
  type: UPDATE_COMPETITORS_HISTORY,
  payload: data,
});

export const addCompetitorsAnalysisResults = (data) => ({
  type: ADD_COMPETITORS_ANALYSIS_RESULTS,
  payload: data,
});

export const setLoadingCompetitors = (loadingCompetitors) => ({
  type: SET_LOADING_COMPETITORS,
  payload: loadingCompetitors,
});


export const toggleInclude = (service) => ({
  type: TOGGLE_INCLUDE,
  payload: {
    service: service,
  },
});

export const setCurrentURL = (url) => ({
  type: SET_CURRENT_URL,
  payload: url
});


export const addCompetitor = (competitor) => ({
  type: ADD_COMPETITOR,
  payload: competitor
});

export const updateAllCompetitors = (newResults) => ({
  type: UPDATE_ALL_COMPETITORS,
  payload: newResults
});

export const toggleSelectCompetitor = (name) => ({
  type: TOGGLE_SELECT_COMPETITOR,
  payload: { name }
});


export const setSWOTResult = (data) => ({
  type: SET_SWOT_RESULT,
  data
})

export const setCurrentTaskId = (task_id) => ({
  type: SET_CURRENT_TASK_ID,
  payload: task_id,
});


export const setHistoryResult = (data) => ({
  type: SET_HISTORY_RESULT,
  data,
});

export const setHistoryData = (data) => ({
  type: SET_HISTORY_DATA,
  data,
});

export const changeHistoryDrawerState = () => ({
  type: SET_HISTORY_DRAWER_STATE
})


export const setAnalysisUrl = (url) => ({
  type: SET_ANALYSIS_URL,
  payload: url,
});

export const restoreDefaultParameters = () => ({
  type: RESTORE_DEFAULT_PARAMETERS,

});

export const deselectAllParameters = () => ({
  type: DESELECT_ALL_PARAMETERS,

});

export const addComparisonParameterToAll = (parameter) => ({
  type: ADD_PARAMETER_TO_ALL,
  payload: parameter,
});

export const updateComparisonParameterFromAll = (parameter) => ({
  type: DELETE_PARAMETER_FROM_ALL,
  payload: parameter,
});


export const addComparisonParameter = (parameter) => ({
  type: ADD_PARAMETER,
  payload: parameter,
});

export const updateComparisonParameter = (parameter) => ({
  type: UPDATE_PARAMETER,
  payload: parameter,
});

export const deleteComparisonParameter = (parameter) => ({
  type: DELETE_PARAMETER,
  payload: parameter,
});

export const removeFromComparison = (url) => ({
  
  type: REMOVE_FROM_COMPARISON,
  payload: url,
});

export const removeErrorAction = () => ({
  type: REMOVE_ERROR_MESSAGE,
});

export const addErrorMessage = (error) => ({
  type: ADD_ERROR_MESSAGE,
  payload: error,
});



export const addToComparison = (url) => ({
  type: ADD_TO_COMPARISON,
  payload: url,
});


export const addComparisonAnswer = (answer) => ({
  type: ADD_COMPARISON_ANSWER,
  payload: answer,
});

export const addAnalysisAnswer = (answer) => ({
  type: ADD_ANALYSIS_ANSWER,
  payload: answer,
});

export const updateAnalysisTaskId = (taskId) => ({
  type: UPDATE_ANALYSIS_TASK_ID,
  payload: taskId,
});

export const resetInsights = () => ({
    type: 'RESET_INSIGHTS',
});

export const resetDeepDives = () => ({
    type: 'RESET_DEEP_DIVES',
});


export const addUserMessage = (id, message, user) => ({
    type: ADD_USER_MESSAGE,
    payload: { id, message, user },
});

export const setAnswerData = (answers) => ({
  type: SET_ANSWER_DATA,
  payload: answers,
});

export const setInsightsData = (messageId, insights) => ({
    type: 'SET_INSIGHTS',
    payload: { id: messageId, insights },
  });

export const setLoadingAnswer = (loadingAnswer) => ({
  type: SET_LOADING,
  payload: loadingAnswer,
});

export const setLoadingSWOT = (loadingSWOT) => ({
  type: SET_LOADING_SWOT,
  payload: loadingSWOT,
});

export const setUtmParams = (utmParams) => ({
  type: SET_UTM_PARAMS,
  payload: utmParams,
});

export const setInterviewServiceUrl = (url) => ({
  type: SET_INTERVIEW_SERVICE_URL,
  payload: url,
});

export const setUrlFetchStatus = (status) => ({
  type: SET_URL_FETCH_STATUS,
  payload: status,
});

export const setSegments = (segments) => ({
  type: SET_SEGMENTS,
  payload: segments,
});

export const setSelectedSegment = (segment) => ({
  type: SET_SELECTED_SEGMENT,
  payload: segment,
});

export const setQuestionnaire = (questionnaire) => ({
  type: SET_QUESTIONNAIRE,
  payload: questionnaire,
});

export const addAnswer = (answer) => ({
  type: ADD_ANSWER,
  payload: answer,
});

export const updateAnswer = (answer) => ({
  type: UPDATE_ANSWER,
  payload: answer,
});

export const resetInterview = (preserveHistory = false) => ({
  type: RESET_INTERVIEW,
  payload: { preserveHistory }
});

export const setActiveStep = (step) => ({
  type: 'SET_ACTIVE_STEP',
  payload: step,
});

export const setInterviewType = (type) => ({
  type: 'SET_INTERVIEW_TYPE',
  payload: type,
});

export const setInterviewGoal = (goal) => ({
  type: 'SET_INTERVIEW_GOAL',
  payload: goal,
});

export const setInterviewTaskId = (taskId) => ({
  type: SET_INTERVIEW_TASK_ID,
  payload: taskId,
});

export const editQuestion = (sectionIndex, questionIndex, newQuestion) => ({
  type: EDIT_QUESTION,
  payload: {
    sectionIndex,
    questionIndex,
    newQuestion
  }
});

export const addNewQuestion = (sectionIndex, question) => ({
  type: ADD_NEW_QUESTION,
  payload: {
    sectionIndex,
    question
  }
});

export const deleteSection = (sectionIndex) => ({
  type: DELETE_SECTION,
  payload: sectionIndex
});

export const diveDeeper = ( section_title, newQuestions) => ({
  type: DIVE_DEEPER,
  payload: {
    section_title,
    newQuestions
  }
});

export const setSectionLoading = (sectionTitle, isLoading) => ({
  type: SET_SECTION_LOADING,
  payload: {
    sectionTitle,
    isLoading
  }
});

export const updateInterviewsHistory = (data) => ({
  type: UPDATE_INTERVIEWS_HISTORY,
  payload: data,
});

export const setInterviewsHistoryResult = (data) => ({
  type: SET_INTERVIEWS_HISTORY_RESULT,
  payload: data,
});

export const setInterviewsPagination = (count) => ({
  type: SET_INTERVIEWS_PAGINATION,
  payload: count
});

export const setSelectedInterview = (interview) => ({
  type: SET_SELECTED_INTERVIEW,
  payload: interview
});

export const setInterviewRunning = (isRunning) => ({
  type: SET_INTERVIEW_RUNNING,
  payload: isRunning
});

export const setInterviewThemes = (themes) => ({
  type: SET_INTERVIEW_THEMES,
  payload: themes,
});

export const setInterviewInsights = (insights) => ({
  type: SET_INTERVIEW_INSIGHTS,
  payload: insights
});

// Task Status Actions
export const setTaskStatus = (status) => ({
  type: SET_TASK_STATUS,
  payload: status
});

export const setTaskProgress = (progress) => ({
  type: SET_TASK_PROGRESS,
  payload: progress
});

export const setTaskError = (error) => ({
  type: SET_TASK_ERROR,
  payload: error
});

export const setSegmentationTaskId = (taskId) => ({
  type: SET_SEGMENTATION_TASK_ID,
  payload: taskId
});

export const setQuestionnaireTaskId = (taskId) => ({
  type: SET_QUESTIONNAIRE_TASK_ID,
  payload: taskId
});

export const setQuestionnaireStatus = (status) => ({
  type: SET_QUESTIONNAIRE_STATUS,
  payload: status
});

export const setQuestionnaireProgress = (progress) => ({
  type: SET_QUESTIONNAIRE_PROGRESS,
  payload: progress
});

export const setQuestionnaireError = (error) => ({
  type: SET_QUESTIONNAIRE_ERROR,
  payload: error
});

export const setAffinityMappings = (mappings) => ({
  type: SET_AFFINITY_MAPPINGS,
  payload: mappings
});

export const setInterviewStatus = (status) => ({
    type: SET_INTERVIEW_STATUS,
    payload: status
});

export const setAnalysisStatus = (status) => ({
    type: SET_ANALYSIS_STATUS,
    payload: status
});

export const setLoaderStatus = (status) => ({
    type: SET_LOADER_STATUS,
    payload: status
});

export const closeBanner = () => ({
  type: CLOSE_BANNER
});

export const resetBanner = () => ({
  type: RESET_BANNER
});

export const setOnboardingCompleted = (completed) => ({
  type: SET_ONBOARDING_COMPLETED,
  payload: { onboarding_completed: completed }
});

export const setWtfAnalysisResults = (results) => ({
  type: SET_WTF_ANALYSIS_RESULTS,
  payload: results
});

export const setWtfAnalysisError = (error) => ({
  type: SET_WTF_ANALYSIS_ERROR,
  payload: error
});

export const setWtfAnalysisStatus = (status) => ({
  type: SET_WTF_ANALYSIS_STATUS,
  payload: status
});

// Add this with other action creators at the bottom
export const resetAllState = () => ({
    type: RESET_ALL_STATE
});
