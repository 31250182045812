import { SET_ANALYSIS_URL, RESET_ALL_STATE } from './actions';

const initialState = {
  analysisUrl: '', // Initialize it to an empty string or null
};

const analysisUrlReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_ALL_STATE:
      return initialState;
    case SET_ANALYSIS_URL:
      return {
        ...state,
        analysisUrl: action.payload, // Update the analysisUrl with the new value
      };
    default:
      return state;
  }
};

export default analysisUrlReducer;