import { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { AccessTimeOutlined, GroupOutlined } from '@mui/icons-material';
import axios from 'axios';
import config from 'config';
import { getAuth } from 'firebase/auth';

const ChurnAnalysis = () => {
  const [churnData, setChurnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const apiUrl = config.apiUrl;

  useEffect(() => {
    const fetchChurnAnalytics = async () => {
      try {
        const token = await auth.currentUser.getIdToken();
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(
          `${apiUrl}/analytics/churn-task-analysis/`,
          { headers }
        );

        setChurnData(response.data);
      } catch (error) {
        console.error('Failed to fetch churn analytics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchChurnAnalytics();
  }, [apiUrl]);

  if (loading) {
    return <div>Loading churn analysis...</div>;
  }

  return (
    <Grid container spacing={3}>
      {/* Stats Cards */}
      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" color="textSecondary">
              Avg. Days Between Last Task and Churn
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <AccessTimeOutlined sx={{ fontSize: 40, color: 'error.main', mr: 2 }} />
              <Typography variant="h3">
                {churnData?.average_days_between_last_task_and_churn.toFixed(1)}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" color="textSecondary">
              Median Days Until Churn
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <AccessTimeOutlined sx={{ fontSize: 40, color: 'warning.main', mr: 2 }} />
              <Typography variant="h3">
                {churnData?.median_days_between_last_task_and_churn.toFixed(1)}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h6" color="textSecondary">
              Total Users Analyzed
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
              <GroupOutlined sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
              <Typography variant="h3">
                {churnData?.total_users_analyzed}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>

      {/* Users Table */}
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>User ID</TableCell>
                <TableCell align="right">Days Until Churn</TableCell>
                <TableCell align="right">Subscription Duration (Months)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {churnData && Object.entries(churnData.user_details).map(([userId, data]) => (
                <TableRow key={userId}>
                  <TableCell component="th" scope="row">
                    {userId}
                  </TableCell>
                  <TableCell align="right">
                    {data.days_between_last_task_and_churn}
                  </TableCell>
                  <TableCell align="right">
                    {data.subscription_duration_months.toFixed(1)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default ChurnAnalysis; 