import { useState, useEffect } from 'react';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';
import MainCard from 'components/MainCard';
import { PersonOutlined, GroupOutlined, AttachMoneyOutlined, PersonAddOutlined } from '@mui/icons-material';
import { getAuth } from 'firebase/auth';
import axios from 'axios';
import config from 'config';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import ChurnAnalysis from 'components/ChurnAnalysis';
// import RetentionAnalysis from 'components/RetentionAnalysis';
import RetentionAnalytics from 'sections/admin/analytics/RetentionAnalytics';

const Dashboard = () => {
  const [subscriptionStats, setSubscriptionStats] = useState(null);
  const [retentionStats, setRetentionStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const apiUrl = config.apiUrl;

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = await auth.currentUser.getIdToken();
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };

        const [subscriptionResponse, retentionResponse] = await Promise.all([
          axios.get(`${apiUrl}/analytics/subscription-analytics/`, { headers }),
          axios.get(`${apiUrl}/analytics/user-retention/`, { headers })
        ]);

        setSubscriptionStats(subscriptionResponse.data);
        setRetentionStats(retentionResponse.data);
      } catch (error) {
        console.error('Failed to fetch dashboard data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [apiUrl]);

  if (loading) {
    return <div>Loading dashboard...</div>;
  }

  const overallMetrics = subscriptionStats?.overall_metrics || {
    total_paying_users: 0,
    currently_active_users: 0,
    average_ltv_usd: 0,
    average_subscription_length_months: 0
  };

  const formatMonthlyData = (monthlyData) => {
    if (!monthlyData) return [];
    
    return Object.entries(monthlyData).map(([month, data]) => ({
      month,
      "New Users": data.new_paying_users,
      "Active Users": data.active_users,
      "Churned Users": data.churned_users,
      "Trials": data.trials || 0
    }));
  };

  return (
    <MainCard title="Admin Dashboard">
      <Grid container spacing={3}>
        {/* Stats Cards */}
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="textSecondary">
                Total Paying Users
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <PersonOutlined sx={{ fontSize: 40, color: 'primary.main', mr: 2 }} />
                <Typography variant="h3">
                  {overallMetrics.total_paying_users}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="textSecondary">
                Active Subscribers
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <GroupOutlined sx={{ fontSize: 40, color: 'success.main', mr: 2 }} />
                <Typography variant="h3">
                  {overallMetrics.currently_active_users}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="textSecondary">
                Average LTV
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <AttachMoneyOutlined sx={{ fontSize: 40, color: 'warning.main', mr: 2 }} />
                <Typography variant="h3">
                  ${overallMetrics.average_ltv_usd}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" color="textSecondary">
                Avg. Subscription Length
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                <PersonAddOutlined sx={{ fontSize: 40, color: 'info.main', mr: 2 }} />
                <Typography variant="h3">
                  {overallMetrics.average_subscription_length_months}m
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Monthly Stats Chart */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Monthly Subscription Data
              </Typography>
              <Box sx={{ width: '100%', height: 400 }}>
                <ResponsiveContainer>
                  <BarChart
                    data={formatMonthlyData(subscriptionStats?.monthly_data)}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="New Users" fill="#8884d8" />
                    <Bar dataKey="Active Users" fill="#82ca9d" />
                    <Bar dataKey="Churned Users" fill="#ff8042" />
                    <Bar dataKey="Trials" fill="#ffd700" />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Churn Analysis Section */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Churn Analysis
              </Typography>
              <ChurnAnalysis />
            </CardContent>
          </Card>
        </Grid>

        {/* Add Retention Analysis Section */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                User Retention Analysis
              </Typography>
              <RetentionAnalytics data={retentionStats} />
            </CardContent>
          </Card>
        </Grid>

        {/* Raw Data (Optional) */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Raw Monthly Data
              </Typography>
              <pre>{JSON.stringify(subscriptionStats?.monthly_data, null, 2)}</pre>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </MainCard>
  );
};

export default Dashboard; 