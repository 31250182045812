import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeBanner } from 'store/reducers/actions';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { CloseOutlined } from '@ant-design/icons';

const Banner = ({ 
    message = "🎉 New Release: Synthetic Interviews - AI-powered user interviews on autopilot. Check it out!", 
    buttonText = "Start Interview" 
    // message = "🎉🎉🎉 We are LIVE on Product Hunt! We'll appreciate your support! 🎉🎉🎉", 
    // buttonText = "Support Us" 
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isVisible = useSelector((state) => state.bannerReducer.isVisible);

  const handleButtonClick = () => {
    // dispatch(closeBanner());
    // Track the click in Yandex Metrika
    if (window.ym) {
      window.ym(94496474,'reachGoal','BUTTON_CLICK');
    }
    
    // Existing code
    navigate('/interviews');
    // window.open('https://www.producthunt.com/posts/askpot-interviews', '_blank');
  };

  if (!isVisible) return null;

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'primary.main',
        color: 'primary.contrastText',
        py: 1,
        px: 2,
        zIndex: 1201,
        boxShadow: 1
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        sx={{ 
          maxWidth: 'xl', 
          mx: 'auto',
          position: 'relative',
          pr: 4 
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2
        }}>
          <Typography variant="body2">
            {message}
          </Typography>
          <Button
            variant="outlined"
            color="inherit"
            size="small"
            onClick={handleButtonClick}
            sx={{ 
              borderColor: 'primary.contrastText',
              minWidth: 'auto',
              whiteSpace: 'nowrap',
              ml: 1
            }}
          >
            {buttonText}
          </Button>
        </Box>
        <IconButton
          size="small"
          color="inherit"
          onClick={() => dispatch(closeBanner())}
          sx={{ 
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)'
          }}
        >
          <CloseOutlined />
        </IconButton>
      </Stack>
    </Box>
  );
};

Banner.propTypes = {
  message: PropTypes.string,
  buttonText: PropTypes.string
};

export default Banner;