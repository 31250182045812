import { ADD_ANALYSIS_ANSWER, UPDATE_ANALYSIS_TASK_ID, RESET_ALL_STATE } from './actions';

const initialState = {
  response: null, // Initialize response as null since it's an object
};

const analysisReducer = (state = initialState, action) => {

    switch (action.type) {
      case RESET_ALL_STATE:
        return initialState;
      case ADD_ANALYSIS_ANSWER:
        return {
          ...state,
          response: action.payload,
        };
      case UPDATE_ANALYSIS_TASK_ID:
        return {
          ...state,
          response: {
            ...state.response,
            analysis_task_id: action.payload,
          },
        };
      default:
        return state;
    }
  };
   
export default analysisReducer;