// assets
import { DashboardOutlined, BarChartOutlined, UserOutlined, SettingOutlined } from '@ant-design/icons';

// Define admin menu items
const admin = {
  id: 'group-dashboard',
  title: 'Admin Panel',
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: 'Dashboard',
      type: 'item',
      url: '/rimash/dashboard',
      icon: DashboardOutlined
    },
    {
      id: 'analytics',
      title: 'Analytics',
      type: 'item',
      url: '/rimash/analytics',
      icon: BarChartOutlined
    },
    {
      id: 'users',
      title: 'User Management',
      type: 'item',
      url: '/rimash/users',
      icon: UserOutlined
    },
    {
      id: 'settings',
      title: 'Settings',
      type: 'item',
      url: '/rimash/settings',
      icon: SettingOutlined
    }
  ]
};

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [admin]
};

export default menuItems; 