import { 
  SET_WTF_ANALYSIS_RESULTS, 
  SET_WTF_ANALYSIS_ERROR,
  SET_WTF_ANALYSIS_STATUS 
} from './actions';

const initialState = {
  results: null,
  error: null,
  status: 'idle', // idle, loading, completed, error
};

const wtfAnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WTF_ANALYSIS_RESULTS:
      return {
        ...state,
        results: action.payload,
        error: null,
        status: 'completed'
      };
    case SET_WTF_ANALYSIS_ERROR:
      return {
        ...state,
        error: action.payload,
        status: 'error'
      };
    case SET_WTF_ANALYSIS_STATUS:
      return {
        ...state,
        status: action.payload
      };
    default:
      return state;
  }
};

export default wtfAnalysisReducer; 