import { lazy } from 'react';
import AdminLayout from 'layout/AdminLayout';
import Loadable from 'components/Loadable';
import AdminGuard from 'utils/route-guard/AdminGuard';
import Dashboard from 'pages/admin/dashboard';
const AnalyticsList = Loadable(lazy(() => import('pages/admin/analytics')));

const AdminRoutes = {
  path: '/rimash',
  element: (
    <AdminGuard>
      <AdminLayout />
    </AdminGuard>
  ),
  children: [

    {
      path: 'analytics',
      element: <AnalyticsList />
    },
    {
      path: 'dashboard',
      element: <Dashboard />
    }
  ]
};

export default AdminRoutes; 