import { useSelector } from 'react-redux';

// material-ui
import { Box, useMediaQuery, useTheme } from '@mui/material';

// project import
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';
import NavButton from './Navigation/NavButton';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { openDrawer } from 'store/reducers/menu';
// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  useEffect(() => {
    if (matchDownSM) {
      
      dispatch(openDrawer({ drawerOpen: false}));

    }
  },[])
  return (
    <SimpleBar

    >
      <Box  sx={{minHeight: '65vh'}}> 
        <Navigation />
        
      </Box>
      <Box   sx={{  position: 'fixed', bottom: 10, p:0,  }} >
        <NavButton  />
      </Box>
        {drawerOpen && !matchDownSM && <></>}
    </SimpleBar>
  );
};

export default DrawerContent;
