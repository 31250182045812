import { getAuth } from 'firebase/auth';
import axios from 'axios';
import config from 'config';
import { setProAccountData } from 'store/reducers/actions';


const fetchPlanInfo = (dispatch) => {
    return new Promise((resolve, reject) => {
        const apiUrl = config.apiUrl;
        const auth = getAuth();

        const fetchIpInfo = async () => {
            try {
                const response = await fetch("https://ipinfo.io/json?token=4f1c89b6de8103");
                const jsonResponse = await response.json();
                return jsonResponse.country;
            } catch (error) {
                console.error('Error fetching country:', error);
                return null;
            }
        };
        
        const utmParams = JSON.parse(localStorage.getItem('utmParams')) || {};
        // console.log('utmParams:', ...utmParams);

        auth.onAuthStateChanged((user) => {
            if (user) {
                user.getIdToken()
                    .then(async (token) => {
                        try {
                            const response = await axios.get(`${apiUrl}/pro_plans/get_account_info/`, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${token}`,
                                },
                            });

                            const JsonResponse = response.data;
                            
                            if (!JsonResponse.country) {
                                const country = await fetchIpInfo();
                                if (country) {
                                    const additionalDataResponse = await axios.post(
                                        `${apiUrl}/pro_plans/get_additional_data_on_register/`,
                                        { country, ...utmParams },
                                        {
                                            headers: {
                                                'Content-Type': 'application/json',
                                                Authorization: `Bearer ${token}`,
                                            },
                                        }
                                    );
                                    JsonResponse.country = additionalDataResponse.data.country;
                                }
                            }

                            dispatch(setProAccountData(JsonResponse));
                            resolve(JsonResponse); // Resolve with the data
                        } catch (error) {
                            console.error('An error occurred:', error);
                            reject(error);
                        }
                    })
                    .catch(reject);
            } else {
                reject(new Error('No authenticated user'));
            }
        });
    });
};



export default fetchPlanInfo;